<!-- to add and remove  white landing nav with class: bds-nav--landing -->
<nav class="bds-nav">
  <div class="bds-container">
    <div class="bds-navLogo">
      <a
        [routerLink]="['']"
        class="bds-navLogoLink"
        style="align-items: center"
      >
        <img
          height="28px"
          [src]="'/assets/img/bdsa-logo-color.svg' | prependCdn"
        />
      </a>
    </div>
  </div>
</nav>
