<footer class="bds-footer">
  <div class="bds-container">
    <div class="bds-footerLeftCont">
      <img height="32px" [src]="'/assets/img/bdsa-logo-white.svg' | prependCdn" alt="BDS logo" class="bds-footerLogo">
      <!-- <ul class="bds-footerSocial">
        <li>
          <a href="#"><img src="/assets/img/icon-facebook-btn.svg" alt="facebook">
      </a>
        </li>
        <li>
          <a href="#"><img src="/assets/img/icon-twitter-btn.svg" alt="twitter">
      </a>
        </li>
        <li>
          <a href="#"><img src="/assets/img/icon-reddit-btn.svg" alt="reddit">
      </a>
        </li>
      </ul> -->
    </div>
    <div class="bds-footerRightCont">
    <span class="bds-footerCopyright">
      ©2018 BLOCKCHAINDS.COM. <span translate>common.all-rights-reserved</span>
    </span>
    <ul class="bds-Policy">
      <li><a [routerLink]="['/terms']"><span class="bds-footerCopyright bds-footerCopyright--policy" translate>register.terms-of-service</span></a></li>
      <li><a [routerLink]="['/distribution']"><span class="bds-footerCopyright bds-footerCopyright--policy" translate>register.distribution-policy</span></a></li>
    </ul>
  </div>
  </div>
</footer>
