import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { PrependCdnPipe } from "./pipes/prepend-cdn.pipe";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { DistributionComponent } from "@app/shared/distribution-policy/distribution.component";
import { TermsComponent } from "@app/shared/terms-of-use/terms.component";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { TermsOfUseStoresComponent } from "./terms-of-use-stores/terms-of-use-stores.component";

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, FormsModule],
  exports: [
    CommonModule,
    TranslateModule,
    PrependCdnPipe,
    HeaderComponent,
    FooterComponent,
    DistributionComponent,
    TermsComponent,
    TermsOfUseStoresComponent,
    PrependCdnPipe,
  ],
  declarations: [
    PrependCdnPipe,
    HeaderComponent,
    FooterComponent,
    DistributionComponent,
    TermsComponent,
    PrependCdnPipe,
    TermsOfUseStoresComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
