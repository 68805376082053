import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, NoPreloading } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("@app/modules/landing/landing.module").then(
        (m) => m.LandingModule
      ),
  },
];

export const AppRoutingModule: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: NoPreloading,
    initialNavigation: "enabledBlocking",
  });
