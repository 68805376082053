export const environment = {
  production: true,
  useSentry: true,
  analytics: 'UA-118698334-1',
  name: 'production',
  timestamp: undefined,
  basePathForRequests: "https://www.aptoide.com",
  cdnPath: "",
  developers_bo: "https://developers.blockchainds.com",
  stores_bo: "https://stores.blockchainds.com",
  userCookieName: 'BDS-USER',
  bdsIdCookieName: 'BDS-SID'
};
