import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@env/environment";

interface IPrependCdnPipePars {
  useOldPath?: boolean;
  width?: number;
  height?: number;
}

@Pipe({
  name: "prependCdn",
  standalone: false,
})
export class PrependCdnPipe implements PipeTransform {
  transform(imagePath: string, pars: IPrependCdnPipePars = {}): string {
    return prependCdn(imagePath, pars);
  }
}

export function prependCdn(imagePath: string, pars: IPrependCdnPipePars = {}) {
  if (imagePath.length && pars.useOldPath) {
    return environment.cdnPath + imagePath;
  }
  // else if (!environment.production) {
  //   return imagePath;
  // }
  return `${environment.cdnPath}${imagePath}`;
}

export function appendTimestamp(path: string) {
  if (!environment.timestamp) {
    return;
  }

  return path.includes("?")
    ? `${path}&t=${environment.timestamp}`
    : `${path}?t=${environment.timestamp}`;
}
