import { Component } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";

@Component({
  selector: "bds-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class HeaderComponent {
  constructor() {}
}
