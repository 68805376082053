import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: false,
})
export class AppComponent {
  constructor(private translate: TranslateService, private router: Router) {
    this.initTranslationService();

    this.router.events.subscribe({
      next: (event: any) => {
        if (!(event instanceof NavigationEnd)) {
          return;
        }

        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      },
    });
  }

  initTranslationService() {
    this.translate.addLangs(["en_GB"]);
    this.translate.setDefaultLang("en_GB");
    this.translate.use("en_GB");
  }
}
