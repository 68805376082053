import {
  HttpBackend,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";

@NgModule({
  exports: [],
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}

export function HttpLoaderFactory(httpClient: HttpBackend) {
  return new MultiTranslateHttpLoader(httpClient, ["/assets/i18n/"]);
}
